import React from 'react'

function Dashboard() {
    return (
        <div>
        hello how r u bro
        </div>
    )
}

export default Dashboard
